export default function JavaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px"
    >
      <path
        fill="#d43a02"
        d="M23.65,24.898c-0.998-1.609-1.722-2.943-2.725-5.455C19.229,15.2,31.24,11.366,26.37,3.999	c2.111,5.089-7.577,8.235-8.477,12.473C17.07,20.37,23.645,24.898,23.65,24.898z"
      />
      <path
        fill="#d43a02"
        d="M23.878,17.27c-0.192,2.516,2.229,3.857,2.299,5.695c0.056,1.496-1.447,2.743-1.447,2.743	s2.728-0.536,3.579-2.818c0.945-2.534-1.834-4.269-1.548-6.298c0.267-1.938,6.031-5.543,6.031-5.543S24.311,11.611,23.878,17.27z"
      />
      <linearGradient
        id="P9ujQJgz7XN9Qbny9S64Ha"
        x1="22.677"
        x2="30.737"
        y1="21.174"
        y2="43.318"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5c65d6" />
        <stop offset=".999" stopColor="#464eb0" />
      </linearGradient>
      <path
        fill="url(#P9ujQJgz7XN9Qbny9S64Ha)"
        d="M32.084,25.055c1.754-0.394,3.233,0.723,3.233,2.01c0,2.901-4.021,5.643-4.021,5.643 s6.225-0.742,6.225-5.505C37.521,24.053,34.464,23.266,32.084,25.055z M29.129,27.395c0,0,1.941-1.383,2.458-1.902 c-4.763,1.011-15.638,1.147-15.638,0.269c0-0.809,3.507-1.638,3.507-1.638s-7.773-0.112-7.773,2.181 C11.683,28.695,21.858,28.866,29.129,27.395z"
      />
      <linearGradient
        id="P9ujQJgz7XN9Qbny9S64Hb"
        x1="19.498"
        x2="27.296"
        y1="22.77"
        y2="44.196"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5c65d6" />
        <stop offset=".999" stopColor="#464eb0" />
      </linearGradient>
      <path
        fill="url(#P9ujQJgz7XN9Qbny9S64Hb)"
        d="M27.935,29.571 c-4.509,1.499-12.814,1.02-10.354-0.993c-1.198,0-2.974,0.963-2.974,1.889c0,1.857,8.982,3.291,15.63,0.572L27.935,29.571z"
      />
      <linearGradient
        id="P9ujQJgz7XN9Qbny9S64Hc"
        x1="18.698"
        x2="26.59"
        y1="23.455"
        y2="45.14"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5c65d6" />
        <stop offset=".999" stopColor="#464eb0" />
      </linearGradient>
      <path
        fill="url(#P9ujQJgz7XN9Qbny9S64Hc)"
        d="M18.686,32.739 c-1.636,0-2.695,1.054-2.695,1.822c0,2.391,9.76,2.632,13.627,0.205l-2.458-1.632C24.271,34.404,17.014,34.579,18.686,32.739z"
      />
      <linearGradient
        id="P9ujQJgz7XN9Qbny9S64Hd"
        x1="18.03"
        x2="25.861"
        y1="24.198"
        y2="45.712"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5c65d6" />
        <stop offset=".999" stopColor="#464eb0" />
      </linearGradient>
      <path
        fill="url(#P9ujQJgz7XN9Qbny9S64Hd)"
        d="M36.281,36.632 c0-0.936-1.055-1.377-1.433-1.588c2.228,5.373-22.317,4.956-22.317,1.784c0-0.721,1.807-1.427,3.477-1.093l-1.42-0.839 C11.26,34.374,9,35.837,9,37.017C9,42.52,36.281,42.255,36.281,36.632z"
      />
      <linearGradient
        id="P9ujQJgz7XN9Qbny9S64He"
        x1="20.725"
        x2="28.228"
        y1="24.582"
        y2="45.197"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5c65d6" />
        <stop offset=".999" stopColor="#464eb0" />
      </linearGradient>
      <path
        fill="url(#P9ujQJgz7XN9Qbny9S64He)"
        d="M39,38.604 c-4.146,4.095-14.659,5.587-25.231,3.057C24.341,46.164,38.95,43.628,39,38.604z"
      />
    </svg>
  );
}
